import React from 'react'
export default function Privacy() {
    return (
        <>
            <div className="whiteBg py-5 hFullSection">
                <div className="container p4-5">
                    <h2 className="text-primary font-weight-bold text-center">CINETIE</h2>
                    <div className="card col-lg-12 mx-auto mt-5 text-dark shadow">
                        <div className="card-body">
                            <h5 className="card-title pb-2 text-center font-weight-bold">Privacy Policy</h5>
                            <hr />
                            <div>



                                <p><strong>Cinetie Privacy Policy</strong></p>
                                <p><strong>Updated: May 14th, 2020</strong></p>
                                <p>Welcome to Cinetie privacy policy. We take our clients&rsquo; information with confidentiality, so we have put together well-thought-out privacy document to keep you informed this privacy document give you details on how your information on our platform is in use or will be used y6please take your time and read through the privacy policy.</p>
                                <p>This Privacy Policy sets forth the policy and procedures of Cinetie. (Variously, &ldquo;&rdquo;, &ldquo;we&rdquo;, &ldquo;our&rdquo; or &ldquo;us) in regard to the collection, usage and disclosure of personal and/or corporate information that you may provide to us through using this website (www.cinetie.com), or by using any service provided by Cinetie (the &ldquo;live streaming platform&rdquo;).</p>
                                <p>If you do not accept this Privacy Policy and/or do not meet and/or comply with the provisions set forth herein, then you may not use our Website.</p>
                                <ol>
                                    <li><strong> Information we collect</strong></li>
                                </ol>
                                <p>When you use our platform, you may provide Cinetie with two main types of information: (1) Non-Personal Information and (2) Personal Information.&nbsp; We collect information from you when you (a) use and/or access our platform, (b) open and/or respond to our emails, (c) contact Cinetie hot line, (c) visit any page online that displays our content, (d) purchase products through our Website, and/or (e) provide information to any of our Service Providers.&nbsp; To view our Website you do not need to submit any Personal Information other than your name, email address, general geographic location, profession and/or industry that you operate in.</p>
                                <p><strong>1.1 Personal Information</strong></p>
                                <p>&ldquo;Personal Information&rdquo; is personally identifiable information that you knowingly choose to disclose, which is collected on an individual basis. We may ask for certain Personal Information from you for the purpose of providing to you content and/or services that you request.&nbsp; We collect Personal Information such as your: (i) contact information (including name, address and email); (ii) financial information (such as credit card number, expiration date, verification number and billing address); (iii) contact information of your company and/or other identity information you share with us (including industry or profession); (iv) location information (such as geographic location of the device you are using) and/or (v) preferences and feedback.&nbsp; By registering, you are authorizing us to collect, store and use your email address, and other such information you provide during registration, in accordance with this Privacy Policy.&nbsp; Once you register, you have opted in to receive electronic communications from Cinetie.</p>
                                <p>&nbsp;</p>
                                <p>1.2 <strong>Non-Personal Information</strong></p>
                                <p>&ldquo;Non-Personal Information&rdquo; is general user information that does not contain personally identifiable information, which is collected on an aggregate basis as you use our Website.&nbsp;&nbsp;&nbsp; We collect Non-Personal Information such as:</p>
                                <p>&nbsp;</p>
                                <p><strong>Cookies:</strong> We use cookies and web log files to track usage and trends, to improve the quality of our Service and to customize your experience.&nbsp; A &ldquo;cookie&rdquo; is a tiny data file that resides on your computer, mobile phone, or other device, and allows Cinetie to recognize you as a user when you return to our Website using the same computer and web browser.&nbsp; Information gathered through cookies may include the date and time of visits, the pages viewed, and time spent at our Website. You can remove or block cookies using the settings in your browser, but in some cases that may impact your ability to use our Website.&nbsp; Sending a cookie to a user&rsquo;s browser enables us to collect Non-Personal information about that user and keep a record of the user&rsquo;s preferences when utilizing our services, both on an individual and aggregate basis. For example, the Company may use cookies to store the following information: session data, email address, username, and preferences. Cinetie may use both persistent and session cookies; persistent cookies remain on your computer after you close your session and until you delete them, while session cookies expire when you close your browser.</p>
                                <p><strong>Analytics Data:</strong> We use third party analytics services to help analyze how users use the Website. These analytics services use cookies to collect information such as how often users visit the Website and we use the information to improve the Website. The analytics services ability to use and share information collected by them is restricted by their terms of use and privacy policy, which you should refer to for more information about how these entities use this information.&nbsp; If third party services collect information, they do so anonymously without identifying individual visitors. However, we may link the information we record using tracking technology to Personal Information we collect.</p>
                                <p><strong>Device Data:</strong> We use device data, which is information concerning a device you use to access, use, and/or interact with the Website, such as operating system type and/or mobile device model, browser type, domain, and other system settings, the language your system uses and the country and time zone of your device, geo-location, unique device identifier and/or other device identifier, mobile phone carrier identification, and device software platform and firmware information.</p>
                                <p><strong>Aggregate:</strong> We may collect non-identifying and statistical information about the use of our Website, such as how many visitors visit a specific page, how long they stay on that page and which links, if any, they click on.&nbsp; This information represents a generic overview of our users, including their collective habits. Information collected in the aggregate is not associated with you as an individual. We may share user information in the aggregate with third parties.</p>
                                <p><strong>Other Tracking Technologies:</strong> We may supplement information you provide to us with information from other sources, such as information to validate and/or update your address and/or other demographic information. This information is used to maintain the accuracy of information on our Website and for internal analysis.&nbsp; We may also use clear gifs, pixel tags and web beacons, which are tiny graphic images placed on website pages and/or in our emails that allow us to determine whether you have performed specific actions and are further used to track online movements of our users.&nbsp; In contrast to cookies, which are stored on your computer&rsquo;s hard drive, clear gifs are embedded invisibly on web pages.&nbsp; We do not tie the information gathered by clear gifs to your Personal Information.</p>
                                <p>&nbsp;</p>
                                <p>1.3 <strong>Information you provide by using the Website</strong></p>
                                <p>You may submit User Content (as defined in the Terms of Use) to the platform, including certain information via online forms. We will not sell the information obtained in any User Content.</p>
                                <p>1.4 <strong>Information you send to other Users</strong></p>
                                <p>This Privacy Policy only addresses the use and disclosure of information we collect from you. If you disclose your information to other parties using the Website, or visit other websites linked through the Website, different rules may apply to their use or disclosure of the information you disclose to them. Since the Cinetie does not control the privacy policies of third parties, or other individual&rsquo;s actions, you are subject to the privacy policies of that third party or those individuals. We encourage you to be sure the recipients are authenticated to your satisfaction before you send them any documents or sensitive information.</p>
                                <ol start="2">
                                    <li><strong> How we use and share information</strong></li>
                                </ol>
                                <p>2.1 <strong>General Use</strong></p>
                                <p>We may share your Personal Information to fulfill the purpose for which you provide it, for any other purpose disclosed by us when you provide the information, with your consent, and/or to third parties designated by you. Except as otherwise stated in this Privacy Policy, we do not sell, trade, rent or otherwise share for marketing purposes your Personal Information with third parties without your consent. For example, we may use your information to:</p>
                                <p>operate, maintain, and improve our sites, products, and Website; respond to comments and questions and provide customer service; provide technical support; send information including confirmations, invoices, technical notices, updates, security alerts, support and administrative messages; communicate about promotions, upcoming events, other news about products and services offered by us and our selected partners; send you reminders, support and marketing messages; manage our administration of the Website;</p>
                                <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                                <p>The information collected in the aggregate enables Cinetie to better understand your use of the platform and to enhance your enjoyment.&nbsp; We may use financial information to process payment for any purchases made on the Website, enroll you in one of our accounts and/or other related services in which you elect to participate.&nbsp; If you use the Website, you agree to receive certain communications from us including but not limited to the following:</p>
                                <p>&nbsp;</p>
                                <p><strong>Special</strong> <strong>Offers, Newsletters and Updates</strong>.</p>
                                <p>We will occasionally send you information on new movies, special deals, promotions and newsletters. You can sign up for these emails from us at any time on our website.&nbsp; Out of respect for your privacy, you may elect not to receive these types of communications by changing your account setting through the Website.</p>
                                <p>&nbsp;</p>
                  
                                <p><strong>Customer Service.</strong></p>
                                <p>Based upon the personally identifiable information you provide us, we will communicate with you in response to your inquiries, to provide the services you request and to manage your account.&nbsp; We will communicate with you by email or telephone, as you may select.</p>
                                <p>&nbsp;</p>
                                <p><strong>Supplementation of Information</strong>.</p>
                                <p>In order to process your credit card orders, we use a third party provider to validate and verify your account information. All billing and account information is transmitted via https, which is a secure encrypted protocol system.</p>
                                <p><strong>2.3 Disclosures</strong></p>
                                <p>We also may be required to disclose an individual&rsquo;s Personal Information in response to a lawful request by public authorities, including meeting national security or law enforcement requirements. For example, we will disclose your Personal Information in the following circumstances: (i) to investigate and defend Cinetie members against any third party claims and/or allegations and/or otherwise to protect Cinetie from liability, (ii) to investigate, prevent and/or take action regarding suspected and/or actual illegal activities, (iii) to assist government enforcement agencies, respond to a legal process and/or comply with the law, (iv) to exercise or protect the rights, property and/or personal safety of the users of the platform and/or (v) to protect the security and/or integrity of the Service.&nbsp; In response to a verified request by law enforcement or other government officials relating to a criminal investigation or alleged illegal activity, we can (and you authorize us to) disclose your name, city, state, telephone number, email address, user ID history, fraud complaints, and usage history, without a subpoena, in connection with an investigation of fraud, intellectual property infringement, piracy, and/or other unlawful activity.</p>
                                <p>&nbsp;</p>
                   
                                <p>2.4 <strong>Business Transitions</strong></p>
                                <p>In the event we undergo a business transaction such as a merger, acquisition by another company, or sale of all or a portion of our assets, your Personal Information may be among the assets transferred and/or examined during the due diligence process. You acknowledge and consent that such transfers may occur and are permitted by this Privacy Policy, and that any acquirer of our assets may continue to process your Personal Information as set forth in this Privacy Policy.</p>
                                <ol start="4">
                                    <li><strong>Service Eligibility and Changes</strong></li>
                                </ol>
                                <p>4.1 <strong>Children and Minors</strong></p>
                                <p>Cinetie does not knowingly collect personally identifiable information from children under the age of thirteen (13).&nbsp; If we learn that we have collected Personal Information from a child under age thirteen (13), we will delete such information as quickly as possible. If you believe that a child under the age of thirteen (13) may have provided us Personal Information, please contact us at <a href="mailto:privacy@cinetie.com">privacy@cinetie.com</a>.&nbsp; By using our platform, you represent that you are at least eighteen (18) years old and understand that you must be at least eighteen (18) years old in order to create an account and/or subscribe to month billing list through our platform.</p>
                                <p>4.2 <strong>Changes to Privacy Policy</strong></p>
                                <p>In general, changes will be made to this Privacy Policy to address new or modified laws, changes to the Rwandan government Privacy law or modified business procedures.&nbsp; However, we may update this Privacy Policy at any time, with or without advance notice, so please review it periodically. We may provide you additional forms of notice of modifications and/or updates as appropriate under the circumstances. Your continued use of the platform after any modification to this Privacy Policy will constitute your acceptance of such modifications and/or updates.&nbsp; You can determine when this Privacy Policy was last revised by referring to the date it was last &ldquo;Updated&rdquo; above.</p>
                                <ol start="5">
                                    <li><strong>Other websites and services</strong></li>
                                </ol>
                                <p>We are not responsible for the practices employed by any websites and/or services linked to and/or from our Website, including the information and/or content contained therein. Please remember that when you use a link to go from our Website to another website and/or service, our Privacy Policy does not apply to such third-party websites and/or services. Your browsing and interaction on any third-party website and/or service, including those that have a link on our Website, are subject to such third party&rsquo;s own rules and policies. In addition, you agree that we are not responsible and do not have control over any third-parties that you authorize to access your Personal Information. If you are using a third-party website and/or service and you allow them to access your Personal Information, you do so at your own risk.</p>
                                <p>&nbsp;</p>
                                <ol start="6">
                                    <li><strong>How we protect information</strong></li>
                                </ol>
                                <p><strong>6.1 Security</strong></p>
                                <p>We implement security measures designed to protect your information from unauthorized access. Your account is protected by your account password and we urge you to take steps to keep your Personal Information safe by not disclosing your password and by logging out of your account after each use. We follow generally accepted industry standards to protect the Personal Information submitted to us, both during transmission and once we receive it. We periodically review our information collection, storage and processing practices, including physical security measures, to guard against unauthorized access to systems. We further protect your information from potential security breaches by implementing certain technological security measures including encryption, firewalls and secure socket layer technology.</p>
                                <p><strong>7.1 Data Integrity Purpose Limitation</strong></p>
                                <p>Cinetie will use Personal Information and User Content only for purpose of delivering the services made available in the platform, which is in the confines live streaming, and to facilitate the services you request related thereto.&nbsp; Cinetie will take reasonable steps to ensure that Personal Information is reliable for its intended use, accurate, complete and current.&nbsp; We may occasionally contact you to determine that your data is still accurate and current.</p>
                                <p>7<strong>.2 Data transfers</strong></p>
                                <p>Please be aware that our Website is subject to United States laws, including laws governing privacy and security of your information.&nbsp; By using our Website, you agree and consent (and represent that you have the authority to provide such consent) to the information collection, use and/or sharing practices described in this Privacy Policy and understand that the laws of the United States and other countries and territories related to the foregoing may differ from those of other countries and may not be as protective as the laws in the country where you reside.&nbsp; Regardless of the laws in place in such countries, we will treat the privacy of your information in accordance with this Privacy Policy.</p>
                                <p>&nbsp;</p>
                                <ol start="8">
                                    <li><strong> Language</strong></li>
                                </ol>
                                <p>The governing language of this Privacy Policy is English, which shall prevail over any other languages used in any translated document.</p>
                                <p>&nbsp;</p>
                                <ol start="9">
                                    <li><strong>How to contact us</strong></li>
                                </ol>
                                <p>If you have questions and/or comments about this Privacy Policy, please email us at <a href="mailto:privacy@cinetie.com">privacy@cinetie.com</a>.</p>




                            </div>

                        </div>
                    </div>

                </div>
            </div>

        </>
    )
}
