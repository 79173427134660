import { SET_SIGNUP_STATUS } from '../actions/actionType';

const initialState = {
    message: '',
    user: {},
    status: null,
};

  const signUpReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_SIGNUP_STATUS:
      return { ...state, status: action.payload.status, user: action.payload.user };
      default:
      return state;
    }
  };
  
  export default signUpReducer;