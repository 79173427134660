import axios from "axios";

export const GpsTracker = async () => {
  try {
    const { data } = await axios.get("https://1.1.1.1/cdn-cgi/trace")
    const ip = data.split("=")[3].split("ts")[0].trim();
    const location = data.match(/loc=([A-Z]{2,3})/g)[0].split("=")[1];
    console.log(ip, location)
    const { data: [{ name }] } = await axios.get(`https://restcountries.com/v3.1/alpha/${location}`);
    localStorage.setItem("country", name.common ?? name.official);
  } catch (error) {
    console.log(error, '======= error ===')
  }

};

export const findContinent = async (country) => {
  //getting continent info by country name
  try {
    const data = await fetch(`https://restcountries.com/v3.1/name/${country}`);
    const result = await data.json();
    return result[0].continents[0];
  } catch (error) {
    console.log(error);
  }
};
