import '../styles/models/navbar.scss';
import React, { Component } from 'react';
import { withRouter } from "react-router";
import avatar from '../assets/images/avatar.png';
import StatusIcon from '../assets/svg/status.svg';
import { NavLink,Link } from 'react-router-dom';
import { MdMovieFilter,MdStars } from 'react-icons/md'
import { BsFillCollectionPlayFill } from 'react-icons/bs'
import { RiMenu4Fill,RiCloseFill } from 'react-icons/ri'
import { RiMovie2Fill } from 'react-icons/ri'
import { AiFillHome } from 'react-icons/ai'
import { IoMdWifi } from 'react-icons/io'


class Header extends Component {
    state = {
        headerColor: 'navbar-dark bg-transparent',
        searchQuery: '',
        logOut:'Log Out',
        showModal: false,
        active:false
    }

    componentDidMount() {
        if (window.innerWidth < 640) {
            this.setState({ headerColor: 'navbar-dark blackBg' })
        }
        else { window.addEventListener('scroll', this.listenScrollEvent) }
    }

    listenScrollEvent = () => {
        const nav = document.querySelector('.navbar')

        if(nav)
        nav.classList.toggle( "headerWithBg" , window.scrollY > 350 )
    }


    searchHandler = (e) => {
        e.preventDefault()
        if(this.state.searchQuery === "")
            this.props.history.push(`/h/search?searchQuery=initial`);
        else
            this.props.history.push(`/h/search?searchQuery=${this.state.searchQuery}`);
    }

    render() {
        const { theme } = this.props;
        return (
            <nav style={{background:theme==="dark"?"#1a1a1a":''}} className={`navbar sticky-navbar fixed-top navbar-expand-lg  py-3 font-weight-bold`}>
                {      
                    window.innerWidth < 800 &&
                    <Link className="navbar-brand text-primary font-weight-bold" to="/h/home">
                        <h1 className="logo" >cinetie</h1>
                    </Link>
                }
                <button 
                    className="navbar-toggler" 
                    type="button" 
                    data-toggle="collapse" 
                    data-target="#navbarSupportedContent" 
                    aria-controls="navbarSupportedContent" 
                    aria-expanded="false" 
                    aria-label="Toggle navigation"
                    onClick={()=> {
                        if (this.state.active)
                            setTimeout(()=> this.setState({ active:false }),600)
                        else
                            this.setState({ active: true })
                    }}
                    >
                    { 
                        !this.state.active ? 
                            <RiMenu4Fill className="bars" size={30} /> : 
                            <RiCloseFill className="bars" size={30} />  
                    }
                </button>
                    { window.innerWidth > 1024 ?
                        <div className="header-one">
                            <ul className="links">
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/h/home">
                                        <AiFillHome size={20} className="icon" />
                                        <span> Home </span>
                                    </NavLink>
                                </li>
                                {
                                    window.innerWidth > 800 &&
                                        <li className="nav-item">
                                            <NavLink className="nav-link" to="/h/previews">
                                                <BsFillCollectionPlayFill size={20} className="icon" />
                                                <span>Featured</span>
                                            </NavLink>
                                        </li>
                                }
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/h/movies">
                                        <MdMovieFilter size={20} className="icon" />    
                                        <span>Movies</span>
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/h/series">
                                        <RiMovie2Fill size={20} className="icon" />
                                        <span>TV Series</span>
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/h/trending">
                                        <IoMdWifi size={20} className="icon" />
                                        <span>Trending</span>
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/h/new">
                                        <MdStars size={20} className="icon" />
                                        <span>Recently Added</span>
                                    </NavLink>
                                </li>
                            </ul>
                            <form onSubmit={this.searchHandler} >
                                <div onClick={()=>this.props.history.push('/h/previews')}>
                                        <img src={StatusIcon} alt="" className="status-icon"/>
                                </div>
                                <div className="col-10">
                                    <div className="input-group mb-2 mr-sm-2">
                                        <input value={this.state.searchQuery} onChange={(e) => this.setState({ searchQuery: e.target.value })} name="searchQuery" className="form-control form-control-sm bg-transparent text-white" type="text" placeholder="Title, gener..."/>
                                        <div className="input-group-prepend">
                                            <button className="btn btn-sm btn-primary" type="submit"> <i className="fa fa-search"></i> </button>
                                        </div>
                                    </div>
                                </div>
                                <ul className="col-2">
                                    <li className="nav-item dropdown">
                                        <Link className="nav-link" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <img className="avatar" src={avatar} alt="" />
                                        </Link>
                                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                                            <Link to="/account"><p className="dropdown-item" href="#">
                                                Settings <i className="fas fa-cogs"></i></p></Link>
                                            <div className="dropdown-divider"></div>
                                            <button className="dropdown-item" onClick={() =>{this.props.history.push('/h/logout')}}>{this.state.logOut} <i className="fas fa-sign-out-alt"></i></button>
                                        </div>
                                    </li>
                                </ul>
                            </form>
                        </div> :
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="menu">
                                <li>
                                    <i className="fas fa-home"></i>
                                    <NavLink to="/h/home">Home</NavLink>
                                </li>
                                <li>
                                    <i className="fas fa-cubes"></i>
                                    <NavLink to="/h/series">Series</NavLink>
                                </li>
                                <li>
                                    <i className="fas fa-video"></i>
                                    <NavLink to="/h/movies">Movies</NavLink>
                                </li>
                                <li>
                                    <i className="fas fa-puzzle-piece"></i>
                                    <NavLink to="/h/new">Recently Added</NavLink>
                                </li>
                                <li>
                                    <i className="fas fa-align-center fa-rotate-90"></i>
                                    <NavLink to="/h/trending">Trending</NavLink>
                                </li>
                                <li>
                                    <i className="fa fa-search"></i> 
                                    <NavLink to="/h/search?searchQuery=initial">Search</NavLink>
                                </li>
                                <li>
                                    <i className="fas fa-cog"></i>
                                    <NavLink to="/account">Settings</NavLink>
                                </li>
                                <li>
                                    <i className="fas fa-shoe-prints"></i>
                                    <span onClick={()=>{this.props.history.push('/h/logout')}}>{this.state.logOut }</span>
                                </li>
                            </ul>
                        </div>
                    }
            </nav>
        )
    }


}

export default withRouter(Header);

export const HeaderTwo = ({ type }) => {

    let info ; 
        if(type === "in") {
            info = {
                link: '/u/signin',
                text: 'Sign In'
            }
        } else if (type === "up") {
            info = {
                link: '/u/signup',
                text: 'Sign Up'
            }
        } else {
            info = {
                link: '/h/logout',
                text: 'Sign Out'
            };
        }
    
    return(
        <div className="HeaderTwo">
            <Link to="/" style={{background:"none"}}>
                <h1 className="logo">cinetie</h1>
            </Link>
            <Link to={info.link}>{info.text}</Link>
        </div>
    )
}