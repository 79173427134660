import React from 'react'
export default function About() {
    return (
        <>
            <div className="whiteBg py-5 hFullSection">
                <div className="container p4-5">
                    <h2 className="text-primary font-weight-bold text-center">CINETIE</h2>
                    <div className="card col-lg-8 mx-auto mt-5 text-dark shadow">
                        <div className="card-body">
                            <h5 className="card-title pb-2 text-center font-weight-bold">About Cinetie</h5>
                            <hr />
                            <div>
                                <p>CINETIE is a media-services provider and production company. The company's primary
                                    business is its subscription-based streaming service, which offers online streaming of a
                                    library of films and television programs. </p>

                                <p>Our business includes but is not limited to streaming, productions,
                                    distribution and lending services of films either online or on DVD.
                                    Our online platform provide library of films which client access via a monthly subscription
                                    basis.</p>

                                 <p className="font-weight-bold">Membership</p>  
                                 <p>Basic Plan: 1 screen plan</p>  
                                 <p>Standard Plan: 2 screens plan</p>  
                                 <p>Premium Plan: 5 screens plan</p>  

                            </div>

                        </div>
                    </div>

                </div>
            </div>

        </>
    )
}
