export const WarninSupressor = () => {
    /**
     * this code below, supresses react
     * wanrning in production mode
     * 
     */
    const backup = console.warn;
    console.warn = function filterWarnings(msg) {
      /**
       * @return {array} of keywords
       * that should be ignored 
       * when found in a warning
       */
      const supressedWarnings = ['Line', 'other warning text'];
    
      if (!supressedWarnings.some(entry => msg.includes(entry))) {
        backup.apply(console, arguments);
      }
    };
}