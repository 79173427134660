import { combineReducers } from 'redux';
import accountReducer from './accountReducer';
import signUpReducer from './signUpReducer';
import signInReducer from './signInReducer';
import homeReducer from './homeReducer';
import UIReducer from './UIReducer';


export default combineReducers({
  signUp: signUpReducer,
  signIn: signInReducer,
  account: accountReducer,
  homeVideos: homeReducer,
  UI: UIReducer,
});
