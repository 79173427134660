import { SET_ACCOUNT_DATA, ADD_NEW_PROFILE, GET_ACCOUNT_DATA, UPDATE_ACCOUNT } from '../actions/actionType';

const initialState = {
    updateStatus: null,
    email: '',
    phone: '',
    plan: '',
    newPassword: '',
    currentPassword: '',
    profiles: [{ id: 0, name: '' }],
    data: {
        user: {
            email: '',
            phoneNumber: '',
            plan: '',
            cityOfResidence: '',
            nationality: '',
        }, paymentMethod: null
    },
};

const accountReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ACCOUNT_DATA:
            return state;

        case ADD_NEW_PROFILE:
            return { ...state, profiles: [...state.profiles, action.payload] };

        case GET_ACCOUNT_DATA:
            return { ...state, ...action.payload }

        case UPDATE_ACCOUNT:
            return { ...state, updateStatus: action.payload };
        default:
            return state;
    }
};

export default accountReducer;