import { DISPLAY_MODAL, SET_CURRENT_VIDEO, CLEAN_MODAL } from './actionType';

export const displayModal = (content) => (dispatch) => {
    dispatch({
        type: DISPLAY_MODAL,
        payload: content
    });
};
export const cleanModal = () => (dispatch) => {
    dispatch({
        type: CLEAN_MODAL
    });
};
export const setCurrVideo = (content) => (dispatch) => {
    dispatch({
        type: SET_CURRENT_VIDEO,
        payload: content
    });
};