import React ,{ Fragment } from 'react';
import { Link } from 'react-router-dom';
import gmail from '../assets/images/gmail.png';
import twitter from '../assets/images/twitter.png';
import whatsapp from '../assets/images/whatsapp.png';

const Footer = ({ theme }) => {
    return (
        <Fragment>
            <div className="footer">
                <section className="left">
                    <Link to="/"> 
                        <h1 className="logo" >cinetie</h1>
                    </Link>
                    <p>© 2020-{new Date().getFullYear()}</p>
                </section>
                <section>
                    <Link to="/a/about">About Cinetie</Link>
                    <Link to="/a/conditions">Terms of Use</Link>
                    <Link to="/a/privacy" >Privacy</Link>
                </section>
                <section>
                    <Link to="/h/home">Home</Link>
                    <Link to="/u/signin">Sign In</Link>
                    <Link to="/u/signup">Sign Up</Link>
                </section>
                <section>
                    <Link className="ig" target="_blank" to="//www.instagram.com/cinetie_official/">Instagram</Link>
                    <Link className="fb" target="_blank" to="//www.facebook.com/Cinetie-102010588217124/">Facebook</Link>
                    <Link className="yt" target="_blank" to="//www.youtube.com/channel/UCgqk-B50mh97MrNLxj-m8zA/featured">Youtube</Link>
                </section>
                <section></section>
            </div>
            <hr/>
            <div className="footer_2">
                <p>Contact us</p>
                <div>
                    <img onClick={() => window.open("mailto:hello@cinetie.com")} alt="" src={gmail} />
                    <img onClick={() => window.open("https://twitter.com/CINETIE01")} alt="" src={twitter} />
                    <img onClick={()=> window.open("https://wa.link/138i2m")} alt="" src={whatsapp} />
                </div>
            </div>
        </Fragment>
    )
}

export default Footer;
