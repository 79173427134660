/* eslint-disable no-console */
import {
  DISPLAY_POPULAR_VIDEOS,
  DISPLAY_NEW_VIDEOS,
  DISPLAY_TRENDING_VIDEOS,
  DISPLAY_FEATURED_VIDEO1,
  DISPLAY_FEATURED_VIDEO2,

  DISPLAY_POPULAR_SERIES,
  DISPLAY_NEW_SERIES,
  DISPLAY_TRENDING_SERIES,
  DISPLAY_FEATURED_SERIE1,
  DISPLAY_FEATURED_SERIE2,

  SEARCH_VIDEO
} from '../actions/actionType';

const initState = {
  popularVideos: [],
  newVideos: [],
  trendingVideos: [],
  featuredVideo1: { video: [{ videoThumbnailLink: '' }] },
  featuredVideo2: { video: [{ videoThumbnailLink: '' }] },

  popularSeries: [],
  newSeries: [],
  trendingSeries: [],
  featuredSerie1: { video: [{ videoThumbnailLink: '' }] },
  featuredSerie2: { video: [{ videoThumbnailLink: '' }] },

  searchResults: []
};

const homeReducer = (state = initState, action) => {
  switch (action.type) {
    case SEARCH_VIDEO:
      return {
        ...state,
        searchResults: action.payload,
      };

    case DISPLAY_FEATURED_VIDEO1:
      return {
        ...state,
        featuredVideo1: action.payload,
      };
    case DISPLAY_FEATURED_VIDEO2:
      return {
        ...state,
        featuredVideo2: action.payload,
      };

    case DISPLAY_POPULAR_VIDEOS:
      return {
        ...state,
        popularVideos: action.payload,
      };
    case DISPLAY_TRENDING_VIDEOS:
      return {
        ...state,
        trendingVideos: action.payload,
      };
    case DISPLAY_NEW_VIDEOS:
      return {
        ...state,
        newVideos: action.payload,
      };


    case DISPLAY_FEATURED_SERIE1:
      return {
        ...state,
        featuredSerie1: action.payload,
      };
    case DISPLAY_FEATURED_SERIE2:
      return {
        ...state,
        featuredSerie2: action.payload,
      };

    case DISPLAY_POPULAR_SERIES:
      return {
        ...state,
        popularSeries: action.payload,
      };
    case DISPLAY_TRENDING_SERIES:
      return {
        ...state,
        trendingSeries: action.payload,
      };
    case DISPLAY_NEW_SERIES:
      return {
        ...state,
        newSeries: action.payload,
      };
    case 'ADD-Popular Videos':
      console.log("got here");
      return {
        ...state,
        popularVideos: [...state.popularVideos,...state.popularVideos]
      }

    default:
      return state;
  }
};

export default homeReducer;
