import React from "react";
import About from "../about/About";
import Privacy from "../about/Privacy";
import Signup from "./signup";
import SignIn from "./signin";
import NewPassword from "./NewPassword";
import VerifyEmail from "./verifyEmail";
import PublicRoute from "../../PublicRoute";
import passwordReset from "./ResetPasswordPage";
import ErrorNotFound from '../../views/notfound';
import { Switch, useRouteMatch } from "react-router-dom";

function HomeRoutes() {
    let { path } = useRouteMatch();
  return (
    <Switch>
      <PublicRoute exact path={`${path}/signup`} component={Signup} />
      <PublicRoute exact path={`${path}/signin`} component={SignIn} />
      <PublicRoute exact path={`${path}/about`} component={About} />
      <PublicRoute exact path={`${path}/privacy`} component={Privacy} />
      <PublicRoute exact path={`${path}/new-password/:id?/:token?`} component={NewPassword} />
      <PublicRoute exact path={`${path}/verifyEmail`} component={VerifyEmail} />
      <PublicRoute exact path={`${path}/passwordReset`} component={passwordReset} />
      <PublicRoute component={ErrorNotFound}/>
    </Switch>
  );
}

export default HomeRoutes;
