export const FETCH_DEPARTMENTS = 'FETCH_DEPARTMENTS'
export const SET_USER_STATUS = 'SET_USER_STATUS'
export const SET_SIGNUP_STATUS = 'SET_SIGNUP_STATUS'
export const SET_ACCOUNT_DATA = 'SET_ACCOUNT_DATA'
export const ADD_NEW_PROFILE = 'ADD_NEW_PROFILE'
export const GET_ACCOUNT_DATA = 'GET_ACCOUNT_DATA'
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT'

export const DISPLAY_POPULAR_VIDEOS = 'DISPLAY_POPULAR_VIDEOS'
export const DISPLAY_TRENDING_VIDEOS = 'DISPLAY_TRENDING_VIDEOS'
export const DISPLAY_NEW_VIDEOS = 'DISPLAY_NEW_VIDEOS'
export const DISPLAY_FEATURED_VIDEO1 = 'DISPLAY_FEATURED_VIDEO1'
export const DISPLAY_FEATURED_VIDEO2 = 'DISPLAY_FEATURED_VIDEO2'

export const DISPLAY_POPULAR_SERIES = 'DISPLAY_POPULAR_SERIES'
export const DISPLAY_NEW_SERIES = 'DISPLAY_NEW_SERIES'
export const DISPLAY_TRENDING_SERIES = 'DISPLAY_TRENDING_SERIES'
export const DISPLAY_FEATURED_SERIE1 = 'DISPLAY_FEATURED_SERIE1'
export const DISPLAY_FEATURED_SERIE2 = 'DISPLAY_FEATURED_SERIE2'

export const SEARCH_VIDEO = 'SEARCH_VIDEO'

export const DISPLAY_MODAL = 'DISPLAY_MODAL'
export const CLEAN_MODAL = 'CLEAN_MODAL'
export const SET_CURRENT_VIDEO = 'SET_CURRENT_VIDEO'


export const DISPLAY_VIDEO_STATISTICS = 'DISPLAY_VIDEO_STATISTICS'
export const DISPLAY_CP_VIDEOS = 'DISPLAY_CP_VIDEOS'
export const DISPLAY_TOTAL_EARNINGS = 'DISPLAY_TOTAL_EARNINGS'
export const DISPLAY_ACTIVE_EARNINGS = 'DISPLAY_ACTIVE_EARNINGS'


export const GET_USERS_IN_RANGE = 'GET_USERS_IN_RANGE'
export const GET_LIVE_USERS = 'GET_LIVE_USERS'
export const GET_ALL_TRANSACTIONS = 'GET_ALL_TRANSACTIONS'

export const DISPLAY_CHECKOUT_HISTORY = 'DISPLAY_CHECKOUT_HISTORY'
export const DISPLAY_LIVE_USERS = 'DISPLAY_LIVE_USERS'
export const DISPLAY_ALL_CP = 'DISPLAY_ALL_CP'
export const DISPLAY_CP_EARNINGS = 'DISPLAY_CP_EARNINGS'

export const DISPLAY_VIDEO_VIEWS = 'DISPLAY_VIDEO_VIEWS'
export const DISPLAY_CP_VIDEO_EARNINGS = 'DISPLAY_CP_VIDEO_EARNINGS'
export const DISPLAY_ADMIN_SALES = 'DISPLAY_ADMIN_SALES'