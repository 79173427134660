import React from 'react';
import axios from 'axios'

class ForgotPassword extends React.Component {

    state = {
        email: '',
        forgotPasswordStatus: '',
        isLoading: false
    }
    emailChangeHandler = (e) => {
        this.setState({ email: e.target.value })
    }
    forgotPasswordHandler = (e) => {
        e.preventDefault()
        this.setState({ isLoading: true })
        axios.post(`${process.env.REACT_APP_API_URL}/auth/forgot-password`,
            { email: this.state.email }
            , {
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8'
                }
            })
            .then(response => {
                this.setState({ isLoading: false })
                if (response.status === 200) {
                    this.setState({ forgotPasswordStatus: response.status, email: '' })
                    alert('Reset password link sent successfully! Check your Email')
                }

            })
            .catch(error => {
                this.setState({ isLoading: false })
                this.setState({ forgotPasswordStatus: error.response.data.message })
            });
    }

    render() {
        return (
            <React.Fragment>
                <form className="form-signin" onSubmit={this.forgotPasswordHandler}>
                    <div className="form-label-group mb-4">
                        <input type="email" className="form-control" placeholder="Enter your email" required autoFocus
                            onChange={this.emailChangeHandler}
                            value={this.state.email}
                        />
                    </div>
                    {this.state.forgotPasswordStatus === 200 ? (
                        <div className="alert alert-success alert-dismissible fade show" role="alert">
                            <h6>Check your email for a password reset link!</h6>
                        </div>
                    ) : (this.state.forgotPasswordStatus !== '' ? (
                        <div className="alert alert-primary alert-dismissible fade show" role="alert">
                            {this.state.forgotPasswordStatus}
                        </div>
                    ) : (null))
                    }
                    <button className="btn btn-lg btn-primary btn-block text-uppercase" type="submit">
                        {this.state.isLoading ? (
                            <div className="spinner-border spinner-border-sm mr-2" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        ) : (null)
                        }
                        Submit
                    </button>
                </form>
            </React.Fragment>
        )
    }
}

export default ForgotPassword