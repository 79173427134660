import React from 'react';
import { useEffect,useState } from 'react';
import map from '../../assets/images/map.png';

const OffLine = () => {

    const [ retrying, setRetry ] = useState(false);

    useEffect(function(){
        if(retrying){
            const id = setTimeout(()=>{
                setRetry(false)
                clearTimeout(id);
            },1000)
        }
    },[retrying]);

    return (
        <div  className="offline">
            <img src={map} alt="" className="globe"/>
            <div className="left">
                <h1>You're Offline</h1>
                <p>Connect to the Internet, and give it another shot</p>
                <i className="fas fa-plug"></i>
                <button onClick={()=>{
                    if(!retrying){
                        setRetry(true)
                    }
                }}>
                    <span>CINETIE</span>
                    <strong>
                    {
                        retrying ?
                        <i className="fas fa-spinner"></i> : 
                        <i className="fas fa-redo"></i>
                    }
                    </strong>
                </button>
            </div>
        </div>
    )
}

export default OffLine;
