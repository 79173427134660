import {Helmet} from "react-helmet";
import React, { Component,createRef } from "react";
import Footer from '../../../components/footer'
import { HeaderTwo } from '../../../components/header';
import { BsPlayFill,BsChevronLeft,BsFillPauseFill } from "react-icons/bs";
import { AiOutlineShareAlt,AiFillSound, AiOutlineArrowLeft, AiFillPlayCircle, AiOutlineClose } from "react-icons/ai";
import { GoMute } from "react-icons/go";
import { VscDebugRestart } from "react-icons/vsc";
import { connect } from 'react-redux';
import { setCurrVideo } from '../../../actions/UIActions'
import '../../../styles/pages/specific.scss';
import { Fetcher } from "../../../helpers/Fetcher"

class Specific extends Component {
  constructor(){
    super();
    this.videoRef = createRef();
    this.state={
      muted : false,
      data:null,
      loading:true,
      paused:false,
      playing:false,
      hasLoaded:false,
      copied:false,
      trailerLink:null,
      isAuthed:false,
      seasons:null,
      canPlay:false,
    }
  }

  componentDidMount() {
    const { id:videoId }  = this.props.match.params;

    if(localStorage.getItem('usertoken')){
      this.setState({ isAuthed: true,videoId })
    }
    // get A video(s) Info
    Fetcher(`/video/sharable/${videoId}`,"GET")
    .then( ({ data }) => this.setState({ data }))
    .catch(err => console.error(err))

    // get trailerLink
    Fetcher(`/trailer/sort/video?id=${videoId}`,"GET")
    .then(response => {
      if(response.statusCode === 200){
        const { trailerLink } = response.data[0];
        this.setState({ trailerLink })
        const { current } = this.videoRef;
        if(current){
          current.src = trailerLink;
          current.load();
        }
      }
    }).catch(err=> console.error(err))

  }

  componentDidUpdate(){
    const { data } = this.state;

    if(data?.type){
      // get all season of the series
      Fetcher(`/series/all/seasons?videoId=${data.videoId}`,"GET")
      .then(res => {
        if(res.statusCode === 200){
          const { seasons } = res.data
          this.setState({ seasons })
        }
      })
      .catch(() => {})
    }
  }

  playerHanlder = (type) => {
    const { data: { videoId,videoName } } = this.state;
    //
    Fetcher(`/series/single/season?s=1&id=${videoId}&&limit=1&offset=0`,"GET")
    .then((res) => {
        this.props.history.push(`/h/view?episode=${res.data.data[0].videoId}&id=${res.data.data[0].id}&type=serie&name=${videoName}`);
    })
    .catch(err => console.log(err.response))
  }

  copyhanlder = () => {
    // copy the url of a specific movie to the clipboard
    navigator.clipboard.writeText(window.location.href)
    //
    this.setState({ copied:true })
    const id = setTimeout(()=>{ 
      this.setState({ copied:false });
      clearTimeout(id);
    } , 5000)
  }

  handlerPlay = type => {
    const { current } = this.videoRef; 
    const { trailerLink,canPlay } = this.state; 

    if(current && trailerLink && canPlay ){
      if( type === 'play' ){
        current.play();
        this.setState({ paused:true })
      }
      else if ( type === 'pause' ){
        current.pause();
        this.setState({ paused:false,playing:false })
      }
      else if ( type === 'restart' ){
        current.currentTime = 0;
      }
      current.addEventListener('timeupdate',()=>{
        this.setState({ playing:true })
      })
    }
  }

  playMovieHandler = () => {
    const { videoId,videoName } = this.state.data;
    this.props.setCurrVideo({ videoId, videoName })
    this.props.history.push(`/h/view?id=${videoId}&type=movie`);
  }

  playSerieHandler = () => {
    const { videoId,videoName } = this.state.data;
    this.props.setCurrVideo({ videoId, videoName })
    this.props.history.push(`/h/view?id=${videoId}&type=serie`);
    // Fetcher(`/series/single/season?s=1&id=${videoId}&&limit=1&offset=0`,"GET")
    // .then((res) => {
    //   if(res.statusCode === 200){
    //     this.props.history.push(`/h/view?episode=${res.data[0].videoId}&id=${res.data[0].id}&type=serie&name=${videoName}`);
    //   }
    // })
    // .catch(err => console.error(err.response))
  }

  playAction = () => {    
    const { 
        data,
        isAuthed,
    } = this.state;

    if(isAuthed){
      if(data.type === 'movie'){
        this.playMovieHandler()
      }else{
        this.playSerieHandler()
      }
    }else{
      this.props.history.replace('/u/signin')
    }
  }

  onError = () => {}

  render() {

    const { 
      data,
      paused,
      muted,
      copied,
      seasons,
      canPlay,
      playing,
      isAuthed,
      trailerLink,
    } = this.state;

    const baseLink = (id) => {
      const newContent = new Date(data.creationTime).getFullYear() > 2021;
      const base = newContent ? 'https://hls-dash-drm-source-1xi7l5k006rpx-thumbnails.s3.amazonaws.com/' :  "https://vod-pipeline-3-thumbnails.s3.eu-west-1.amazonaws.com/";
      //
      return base + id
    }


    const helmet = (
      <Helmet>
        <title>{data?.videoName } &bull; Cinetie { data?.type === 'movie' ? "Movie" : 'Serie' }</title>
        <meta name="description" content={ data?.videoSynopsis }/>
        <meta property="og:image" content={ baseLink + data?.videoThumbnailLink } />
      </Helmet>
    )

    return (
          <div className="specific-parent" >
            { window.innerWidth > 800 && <HeaderTwo type={ isAuthed ? "out" : "in" } />}
            {
                data ?
                  <>
                    {helmet}
                    <div className="specific-container">
                      <section>
                        <div className="blury-wrapper">
                          <img className="blury_image" src={baseLink(data.videoThumbnailLink)} alt="" />
                        </div>
                        {
                          window.innerWidth < 800 &&
                            <div className="back" onClick={()=> {
                                if(isAuthed){
                                  this.props.history.goBack()
                                }else{
                                  this.props.history.replace('/u/signin')
                                }
                              }}>
                              <AiOutlineArrowLeft size={20} />
                              <span>Go Back</span>
                              <AiOutlineClose size={20} />
                            </div>
                        }
                        <div className="Tall-avatar">
                          {
                            window.innerWidth < 800 &&
                            <AiFillPlayCircle 
                              onClick={()=>this.playAction()} 
                              className="mobile-play-btn" 
                              size={60}
                            /> 
                          }
                          <img src={baseLink(data.tallThumbNailLink)} alt="" />
                        </div>
                        <div className="content">
                          {
                            window.innerWidth > 800 &&
                              <div className="back" onClick={()=> {
                                if(isAuthed){
                                  this.props.history.goBack()
                                }else{
                                  this.props.history.replace('/')
                                }
                              }}>
                                <BsChevronLeft/>
                                <span>Back</span>
                              </div>
                          }
                          <div className="header">
                            <h1>{data.videoName}</h1>
                            { 
                              data.type === 'serie' ?
                                <span className="type">
                                  <strong>{data.type} </strong> 
                                  / Movie
                                </span> :
                                <span className="type">
                                  Serie / 
                                  <strong> {data.type}</strong>
                                </span> 
                            }
                          </div>
                          <span className="features"> {new Date(data.creationTime).getFullYear()}  | {data.rate} {data.category.split(",").map((one,index) => <span key={index}> | {one} </span>)} </span>
                          <h4>Synopsis</h4>
                          <p>{data.videoSynopsis}</p>
                          <h4>Cast</h4>
                          <h6>{data.videoCast.split(",").map(one => <span className="cast"> {one} , </span>)}</h6>
                          <h4> 
                            Subtitles : {" "} 
                          <span className="subtitles">
                            { !data.EnSubt ? <span>English</span> : <strong>English</strong>  }
                            <strong> | </strong>
                            { !data.FrSubt ? <span>French</span> : <strong>French</strong> }
                          </span>
                          </h4> 
                          <h4 style={{marginTop:".5em"}}>
                            Audio language : <span>{ data?.language || 'Unspecified' }</span>
                          </h4>
                          
                          {
                            seasons && <h4>{seasons} Season{seasons > 1 && 's' }</h4>
                          }
                          {
                            // show this laptops only
                            window.innerWidth > 800 &&
                            <div className="btns">
                              <button onClick={()=>this.playAction()} >
                                <BsPlayFill className="icon2" style={{marginRight:"10px"}}/>
                                <span>Watch {data.type}</span>
                              </button>
                              <button onClick={()=>this.copyhanlder()}>
                                <AiOutlineShareAlt className="icon" style={{marginRight:"10px"}}/>
                                { 
                                  copied? 
                                  <span style={{color:"green",fontWeight:"bold"}}>Copied</span>:
                                  <span>Share</span>
                                }
                              </button>
                            </div>
                          }
                        </div>
                      </section>
                      <section>
                        <div className="controls">
                          {
                            paused ?
                              <BsFillPauseFill className="muter" onClick={()=> this.handlerPlay('pause')} size={40}/>:
                              <BsPlayFill style={{ cursor: trailerLink && canPlay ? "auto" : "not-allowed" }} className="muter" onClick={()=> this.handlerPlay('play')} size={40}/>
                          }
                          {
                            muted ?
                              <AiFillSound className="muter" onClick={()=> this.setState({ muted:false }) } size={40} />:
                              <GoMute style={{ cursor: trailerLink && canPlay ? "auto" : "not-allowed" }} className="muter" size={40} onClick={()=> { if(trailerLink)this.setState({ muted:true }) }} />
                          }

                          <VscDebugRestart  style={{ cursor: trailerLink && canPlay ? "auto" : "not-allowed" }} className="muter" onClick={()=> this.handlerPlay('restart')} size={40} />
                        </div>
                        <div className="trailer">
                          <section></section>
                          <h3 style={{opacity: playing && paused ? 0.3 : 1}}> {data.type} Trailer</h3>
                          <video onCanPlay={()=>this.setState({ canPlay:true })} ref={this.videoRef} muted={muted} />
                        </div>
                      </section>
                    </div>
                  </> :
                  <div className="specific-loader">
                      <div id="ring">
                      <span>CINETIE</span>
                      <span id="spanner"></span>
                      </div>
                  </div> 
            }
            <footer className="specific-footer">
              <Footer/>
            </footer>
          </div>
        )
  }
}

export default connect(undefined,{ setCurrVideo })(Specific)
