import React from 'react';
import PasswordReset from './passwordReset';

class ForgotPassword extends React.Component {
    render() {
        return (
            <React.Fragment>
                <div className="hFullSection pt-5">
                    <div className="container pt-5">
                        <h2 className="text-primary text-center font-weight-bold">CINETIE</h2>
                        <div className="card col-lg-6 mx-auto mt-5 text-dark">
                            <div className="card-body text-center">
                                <h5 className="card-title pb-2">Reset Password</h5>
                                <PasswordReset />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default ForgotPassword