import React from 'react';
import { Route, Redirect } from 'react-router-dom';

class PublicRoute extends React.Component {
    render() {
        if (this.props.path === '/a/conditions' || this.props.path === '/a/privacy') {
            return <Route {...this.props} />
        }
        if (this.props.path === '/a/about') {
            return <Route {...this.props} />
        }
        if(this.props.path === '/h/logout'){
            return <Route {...this.props}/>
        }
        if (!localStorage.getItem('usertoken')) {
            return <Route {...this.props} />
        } else {
            return <Redirect to='/h/home' />
        }
    }
}

export default PublicRoute;
