import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { HeaderTwo } from "../../components/header";
import movies from "../../assets/images/movies.png";
import { setSignUpStatus } from "../../actions/signUpActions";
import { Helmet } from "react-helmet";
import { Fetcher } from "../../helpers/Fetcher";

class Signup extends React.Component {
  state = {
    email: "",
    error: "",
    password: "",
    promoCode: "",
    isLoading: false,
    hasPromoCode: false,
    confirmPassword: "",
    toggledConfirm: false,
    toggledPassword: false,
    agree: false,
  };

  handleCheckboxChange = (event) => {
    // handle checkbox change
    this.setState({ agree: event.target.checked });
  };

  handleSignup = () => {
    this.setState({ agree: false }); // reset checkbox state
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleCheckBoxChange = (e) => {
    this.setState((prevState) => ({
      hasPromoCode: !prevState.hasPromoCode,
    }));
  };

  signupHandler = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true, error: "" });

    const userInput = {
      email: this.state.email.trim(),
      password: this.state.password,
    };

    const promo = {
      promoCode: this.state.promoCode,
    };

    const isData = this.state.hasPromoCode ? promo : null;

    const userData = {
      ...userInput,
      ...isData,
    };

    if (!this.state.isLoading) {
      this.setState({ isLoading: true });

      Fetcher("/auth/signup", "POST", userData)
        .then((response) => {
          if (response.statusCode === 201) {
            if (response.data.data !== undefined) {
              if (response.data.data.link === undefined) {
                alert(response.data.data.message);
              } else {
                window.location.assign(response.data.data.link);
              }
            } else {
              localStorage.setItem("usertoken", response.data);
              window.location.assign("/account/check-account");
            }
          } else {
            this.setState({ isLoading: false, error: response.message });
          }
        })
        .catch(() => this.setState({ error: "Try again later" }))
        .finally(() => this.setState({ isLoading: false }));
    }
  };

  handleToggle = (type) => {
    this.setState((prevState) => ({
      [type]: !prevState[type],
    }));
  };

  render() {
    const {
      email,
      password,
      confirmPassword,
      isLoading,
      error,
      toggledPassword,
      toggledConfirm,
      promoCode,
      hasPromoCode,
    } = this.state;

    return (
      <div className="Auth">
        <HeaderTwo type="in" />
        <Helmet>
          <title>Cinetie &bull; Signup</title>
        </Helmet>
        <div className="parent">
          <form onSubmit={this.signupHandler}>
            <h1>
              Register {window.innerWidth > 800 && <br />}Now .
            </h1>
            <div className="input-field">
              <input
                value={email}
                onChange={this.handleChange}
                name="email"
                id="email"
                placeholder="Email"
              />
            </div>
            <div className="input-field">
              <input
                value={password}
                onChange={this.handleChange}
                id="password"
                name="password"
                type={toggledPassword ? "text" : "password"}
                placeholder="Password"
              />
              {toggledPassword ? (
                <i
                  onClick={() => this.handleToggle("toggledPassword")}
                  className="fas fa-eye-slash"
                ></i>
              ) : (
                <i
                  onClick={() => this.handleToggle("toggledPassword")}
                  className="fas fa-eye"
                ></i>
              )}
            </div>
            <div className="input-field">
              <input
                value={confirmPassword}
                onChange={this.handleChange}
                id="confirmPassword"
                name="confirmPassword"
                type={toggledConfirm ? "text" : "password"}
                placeholder="Confirm Password"
              />
              {toggledConfirm ? (
                <i
                  onClick={() => this.handleToggle("toggledConfirm")}
                  className="fas fa-eye-slash"
                ></i>
              ) : (
                <i
                  onClick={() => this.handleToggle("toggledConfirm")}
                  className="fas fa-eye"
                ></i>
              )}
            </div>
            <div className="input-check">
              <input
                checked={hasPromoCode}
                type="checkbox"
                onChange={this.handleCheckBoxChange}
                id="hasPromoCode"
                name="hasPromoCode"
              />
              <label htmlFor="scales">Do you have a Promo code ?</label>
            </div>
            {hasPromoCode && (
              <div className="input-field">
                <input
                  value={promoCode}
                  onChange={this.handleChange}
                  name="promoCode"
                  id="promoCode"
                  placeholder="Promo Code"
                />
              </div>
            )}
            {hasPromoCode && promoCode === "" && (
              <div>
                <small className="match-error">
                  Please enter your promo code
                </small>
              </div>
            )}
            {(password !== "" && confirmPassword !== "") &&
              (password !== confirmPassword) && (
                <small className="match-error">Passwords don't match</small>
              )}
            <div style={{ marginTop: "20px" }}>
              <p>
                <input
                  type="checkbox"
                  name="agree"
                  id="agree"
                  checked={this.state.agree}
                  onChange={this.handleCheckboxChange}
                />
                {" "}
                By signing up you agree to our{" "}
                <Link to="/a/conditions">Terms and conditions</Link>
              </p>
            </div>
            <p className="error">
              {error && (
                <>
                  {" "}
                  <span>⚠️</span> {error}{" "}
                </>
              )}
            </p>
            <button
              disabled={
                email === "" ||
                password === "" ||
                confirmPassword === "" ||
                (hasPromoCode && promoCode === "") ||
                !this.state.agree
              }
            >
              {isLoading ? (
                <i className="spiner fas fa-spinner"></i>
              ) : (
                <span>Sign up</span>
              )}
            </button>
            <p>
              New To Cinetie ? <Link to="signin">Sign In here</Link>
            </p>
          </form>
          <section>
            <img src={movies} alt="" />
          </section>
        </div>
      </div>
    );
  }
}

Signup.propTypes = {
  setSignUpStatus: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    signUp: state.signUp,
    plan: state.plan,
  };
};

export default connect(mapStateToProps, { setSignUpStatus })(Signup);
