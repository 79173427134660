import React, { useEffect } from 'react';
import Footer from '../../components/footer';
import movies from '../../assets/images/movies.png';
import { HeaderTwo } from '../../components/header';
import screens from '../../assets/images/screens.png';
import arrow from '../../assets/images/chevron-right.png';
import downloader from '../../assets/images/downloader.png';

const LanindPage = () => {
  useEffect(()=>{
		document.title = "Cinetie - Watch Movies & Tv Series";
    document.querySelector('.float-right').click();
  },[])
  return (
    <div className="landing">
      <HeaderTwo type="in"/>
      <div className="zero">
        <section >
          <div className="left">
            <h1>All of Cinetie</h1>
            <h3>
              Panafrican Movies, Series, <br/> TV
              Shows, and More.
            </h3>
            <button onClick={()=> window.location.assign('/u/signup')}>
              <span>
                get started
              </span>
              <img src={arrow} alt=""/>
            </button>
          </div>
        </section>
      </div>
      <div className="one">
        <section  className="left">
          <h1>Watch Everywhere</h1>
          <p>
            Stream unlimited movies and TV <br/> 
            shows on your phone, tablet, laptop, <br/> 
            and TV without paying more.
          </p>
        </section>
        <section className="right">
          <img src={movies} alt=""/>
        </section>
      </div>
      <div className="two">
        <section  className="left">
          <h1>Enjoy on your  devices</h1>
          <p>
            Watch on Smart TVs, Chromecast, <br/> 
            Apple TV, Blu-ray players, and  more.
          </p>
        </section>
        <section className="right">
          <img src={screens} alt=""/>
        </section>
      </div>
      <div className="three">
        <section className="left">
          <h1>
            Download your shows <br/>
            to watch offline.
          </h1>
          <p>
            Save your favorites easily and always have { window.innerWidth > 800 && <br/>}
            something to watch.
          </p>
        </section>
        <section className="right">
          <img src={downloader} alt=""/>
        </section>
      </div>
      <div className="four">
        <h1>Frequently Asked Questions</h1>
        <div className="accordion" id="accordionExample">
          <div className="card bg-dark text-white">
            <div className="card-header" id="headingOne">
              <span className="mb-0" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                <h4>What Is CINETIE ? <span className="float-right">+</span> </h4>
              </span>
            </div>

            <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
              <div className="card-body">
                <p className="text-lg">
                  <span className="logo">CINETIE</span> is a media-services provider and production company. The company's primary business is its subscription-based streaming service which offers online streaming from a library of Movies and Television programs.
                </p>
              </div>
            </div>
          </div>
        </div>
        <button onClick={()=> window.location.assign('/u/signup')}>
          <span>
            get started
          </span>
          <img src={arrow} alt=""/>
        </button>
      </div>
      <Footer/>
    </div>
  )
}

export default LanindPage;
