import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";
import { 
  isSafari,
  isMobileSafari
} from "react-device-detect";
import store from './store';
import { Provider } from 'react-redux';
import PublicRoute from "./PublicRoute";
import Offline from "./views/cinetie/offline";
import { GpsTracker } from "./helpers/gps";
import ErrorNotFound from "./views/notfound";
import HomeRoutes from "./views/home/HomeRoutes";
import LandingPage from "./views/home/landingPage";
import Specific from "./views/cinetie/routes/specific";
import { WarninSupressor } from "./helpers/WarningSupresser";
import React, { Suspense, lazy, useEffect,useState } from "react";

// Lazy Components
const AboutRoutes = lazy(() => import("./views/about/AboutRoutes"));
const CinetieRoutes = lazy(() => import("./views/cinetie/CinetieRoutes"));
const AccountRoutes = lazy(() => import("./views/account/AccountRoutes"));

const  App = () =>  {
  const [ Network,setNetWork ] = useState(null)

  useEffect(()=>{
    
    const id = setInterval(()=> NetworkHandler() ,2000);

    const NetworkHandler = () => {
      /**
       * checking if there's internet if on | Off 
       * and set the offline page component 
       * instead of the App components
       */
      if(navigator.onLine !== Network)
      setNetWork(navigator.onLine)
    }
    
    NetworkHandler();
    return () => clearInterval(id)
  },[Network])

  useEffect(()=>{
    GpsTracker();
    /**
     * this code below, supresses react
     * wanrning in production mode
     * 
     */
     WarninSupressor()
  },[])

  const Alert = () => {
    if (isSafari || isMobileSafari)
      return (
        <div
          class="alert alert-warning alert-dismissible fade show"
          role="alert"
        >
          <strong>Safari</strong> is not supported. Download
          <strong> Chrome/Opera/Firefox</strong>
          <button
            type="button"
            class="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      );
    return "";
  };

  if( Network === null)
  return (
    <div className="specific-loader">
      <div id="ring">
      <span>CINETIE</span>
      <span id="spanner"></span>
      </div>
    </div>
  )

  if( Network === false )
  return <Offline />

  return (
    <Provider store={store}>
      <Router>
        { window.innerWidth > 800 && <Alert /> }
        <Suspense
          fallback={
            <div id="loader">
              <div id="ring">
                <span>CINETIE</span>
                <span id="spanner"></span>
              </div>
            </div>
          }
        >
          <Switch>
            <PublicRoute exact path="/">
              <LandingPage />
            </PublicRoute>
            {/* Auth Routes */}
            <Route path="/u">
              <HomeRoutes />
            </Route>
            {/* CINETIE Routes */}
            <Route path="/h">
              <CinetieRoutes />
            </Route>
            {/* Settings Routes */}
            <Route path="/account">
              <AccountRoutes />
            </Route>
            {/* About Routes */}
            <Route path="/a">
              <AboutRoutes />
            </Route>
            {/* 404 NotFound Route */}
            <Route path="/video/:id" component={Specific} />
            <Route component={ErrorNotFound} />
          </Switch>
        </Suspense>
      </Router>
    </Provider>
  );
};

export default App;
