const { REACT_APP_API_URL } = process.env;

export const controller = new AbortController()

export const Fetcher = async (url, method, Body) => {

  const { signal } = controller
  const ip = localStorage.getItem("ip")
  const country = localStorage.getItem("country")
  const token = localStorage.getItem("usertoken")
    
  const headers = {
    ip,
    country,
    "Accept": "application/json",
    "Content-Type": "application/json",
    "Authorization": `Bearer ${token}`,
  }

  /** 
   * If there's no token,country or ip in the cache
   * Remove the auth header 
   * */
  if(!token)
  delete headers.Authorization

  if(!ip)
  delete headers.country 

  if(!ip)
  delete headers.ip
  //

  const response = await fetch(REACT_APP_API_URL+url, {
    headers,
    method,
    signal,
    body:JSON.stringify(Body),
  });
  
  return response.json();
};
