import $ from "jquery";
import React from 'react';
import 'jquery/dist/jquery';
import './styles/main.scss';
import ReactDOM from 'react-dom';
import 'jquery/dist/jquery.slim';
import 'bootstrap/dist/js/bootstrap.min.js';
import reportWebVitals from './reportWebVitals';
import 'font-awesome/css/font-awesome.min.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import App from './App';
window.$ = $;
window.jQuery = $;


window.$ = $;
window.jQuery = $;

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorkerRegistration.register();
reportWebVitals();
