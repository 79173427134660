/* eslint-disable no-console */
import { DISPLAY_MODAL, SET_CURRENT_VIDEO, CLEAN_MODAL } from '../actions/actionType';

const initialState = {
  content: { title: '', cast: '', synopsis: '', img: '', video: '', videoId: '' }
};

const VideoRreducer =  (state = initialState, action) => {
  switch (action.type) {
    case DISPLAY_MODAL:
      return {
        ...state,
        content: action.payload,
      };
    case CLEAN_MODAL:
      return { ...initialState };
    case SET_CURRENT_VIDEO:
      return {
        ...state,
        content: action.payload,
      };

    default:
      return state;
  }
};

export default VideoRreducer;
