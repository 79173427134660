import React from 'react';
import { Link } from 'react-router-dom';
import queryString from 'query-string';

class VerifyEmail extends React.Component {

    state = {
        type: 'user'
    }

    componentDidMount(){
        const params = queryString.parse(this.props.location.search);
        if (params.type === 'cp') this.setState({ type: 'cp' })
    }

    render() {
        return (
            <React.Fragment>
                <div className="pt-5 hFullSection">
                    <div className="container pt-5">
                        <h2 className="text-primary text-center font-weight-bold">CINETIE</h2>
                        <div className="card col-lg-6 mx-auto mt-5 text-dark shadow">
                            <div className="card-body text-center">
                                <h5 className="card-title pb-2">Verify Email</h5>
                                <h6 className="card-subtitle mb-2 text-muted">Please Check The Link We Sent In your Inbox In Order To Verify Your Email.</h6>
                                    {this.state.type === 'user' ? (<Link to="/u/signin" className="btn btn-primary mt-3">Sign In Here</Link>) : 
                                    (<Link to="/cp/signin" className="btn btn-primary mt-3">Sign In Here</Link>)}
                            </div>
                        </div>

                    </div>
                </div>

            </React.Fragment>
        )
    }
}

export default VerifyEmail