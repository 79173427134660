import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { HeaderTwo } from '../../components/header';
import movies from '../../assets/images/movies.png';
import { setUserStatus } from '../../actions/signInActions';
import { Helmet } from 'react-helmet';
import { Fetcher } from '../../helpers/Fetcher';

class Signin extends Component {

	state = {
		error: '',
		email: '',
		password: '',
		isLoading: false,
		toggled: false
	}

	componentDidMount() {
		const url = new URLSearchParams(window.location.search)
		const token = url.get('token');
		const action = url.get('action');

		if (token) {
			localStorage.setItem('usertoken', token);
			if (action) window.location.assign('/account/pricing')
			else window.location.assign('/account/check-account');
		}
	}

	handlerChange = e => {
		const { id, value } = e.target;
		this.setState({ [id]: value, error: '' })
	}

	loginHandler = (e) => {
		e.preventDefault();
		if (!this.state.isLoading) {
			// if there's no request if progress already
			this.setState({ isLoading: true, signinStatus: "", error: "" })
			this.props.setUserStatus('loading', this.state);
			/**
			 * @type {email} user email address
			 * @type {password} string - user password 
			 */
			const { email, password } = this.state;
			//
			Fetcher('/auth/login', "POST", { email: email.trim(), password })
				.then(response => {
					this.setState({ isLoading: false })
					if (response.statusCode === 200) {
						if (response.data.data !== undefined) {
							if (response.data.data.link === undefined) {
								alert(response.data.data.message)
							} else {
								if (response.data.data.link) {
									return window.location.assign(response.data.data.link)
								} 

							}
						} else {
							localStorage.setItem('usertoken', response.data);
							window.location.assign('/account/check-account');
						}
					}
					else {
						this.setState({ error: response.message })
					}
				})
				.catch(() => this.setState({ isLoading: false, error: "Try Again Later" }));
		}
	}

	handlerToggle = (value) => {
		this.setState({ toggled: !value })
	}

	render() {
		const { email, password, error, isLoading, toggled } = this.state;
		return (
			<div className="Auth">
				<HeaderTwo type="up" />
				<Helmet>
					<title>Cinetie &bull; Signin</title>
				</Helmet>
				<div className="parent">
					<form onSubmit={this.loginHandler}>
						<h1>
							Welcome {window.innerWidth > 800 && <br />}
							Back .
						</h1>
						<div className="input-field">
							<input
								value={email}
								onChange={this.handlerChange}
								name="email"
								id="email"
								placeholder="Email" />
						</div>
						<div className="input-field">
							<input
								value={password}
								onChange={this.handlerChange}
								id="password"
								name="password"
								type={toggled ? "text" : "password"}
								placeholder="Password"
							/>
							{
								toggled ?
									<i onClick={() => this.handlerToggle(toggled)} className="fas fa-eye-slash"></i> :
									<i onClick={() => this.handlerToggle(toggled)} className="fas fa-eye"></i>
							}
						</div>
						<div>
							<Link className="forgot" to="passwordReset">Forgot Password ?</Link>
						</div>
						<p className="error">{error && <> <span>⚠️</span>  {error} </>}</p>
						<button disabled={email === "" || password === "" ? true : false}>
							{
								isLoading ?
									<i className="spiner fas fa-spinner"></i> :
									<span>Sign In</span>
							}
						</button>
						<p>New To Cinetie ? <Link to="signup">Sign Up Now</Link></p>
					</form>
					<section>
						<img src={movies} alt="" />
					</section>
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		signIn: state.signIn,
	}
};

export default connect(mapStateToProps, { setUserStatus })(Signin)
