import React from 'react';
import { Helmet } from 'react-helmet';
import NotFoundIcon from '../assets/images/404.png';
import { FiChevronLeft, FiHome } from 'react-icons/fi'
import { Link } from 'react-router-dom';


const ErrorNotFound = (props) => {
	const BackHandler = () => props.history.goBack();
	return (
		<>
			<Helmet>
			  <title>Cinetie - Page Not Found</title>
			</Helmet>
			<div className="hFullSection d-flex align-items-center">
				<div className="container">
					<div className=" col-lg-10 mx-auto mt-4 text-white">
						<div className="row d-md-flex">
							<div className="col-lg-6 my-auto">
								<h1 className="text-primary font-weight-bold">CINETIE</h1>
								<div className="my-5">
									<h1 className="font-weight-bold">Whoops...</h1>
									<h5 className="text-muted">
										Page Not Found ! { window.innerWidth > 800 && <br/>}
										Sorry, We couldn't find the page you are Looking For.
									</h5>
								</div>
								<div>
									<Link className="btn btn-primary" href="#" onClick={()=>BackHandler()}>
										<FiChevronLeft size={25} className="mr-2" />
										<span>Back</span>
									</Link>
									<Link className="btn btn-primary ml-3" href="/h/home">
										<FiHome size={20} className="mr-2 mb-1" />
										<span>Take Me Home</span>
									</Link>
								</div>
							</div>
							<div className="col-lg-6 mt-5">
								<img className="img-fluid" src={NotFoundIcon} alt="" srcSet="" />
							</div>
						</div>
					</div>
	
				</div>
			</div>
	
		</>
	);
}


export default ErrorNotFound;